import { useState, useEffect, useContext } from "react";
import Layout from "../components/Layout";
import CssBaseline from '@mui/material/CssBaseline';
import "../styles/globals.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useRouter, asPath } from "next/router";
import PageLoader from "../components/loader";
import { AuthProvider } from '../context/AuthContext';

function MyApp({ Component, pageProps }) {
  const [loading, setLoading] = useState(false);
  const Router = useRouter();

  useEffect(() => {
    Router.events.on("routeChangeStart", () => setLoading(true));
    Router.events.on("routeChangeComplete", () => setLoading(false));
    Router.events.on("routeChangeError", () => setLoading(false));
    return () => {
      Router.events.off("routeChangeStart", () => setLoading(true));
      Router.events.off("routeChangeComplete", () => setLoading(false));
      Router.events.off("routeChangeError", () => setLoading(false));
    };
  }, [Router.events]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loading ? (
        <PageLoader />
      ):(
        <AuthProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </AuthProvider>
      )}
    </ThemeProvider>
  )
}

export default MyApp;
