import React, { useState } from "react";
import { Stack } from "@mui/material";
import { Watch } from "react-loader-spinner";

function loader() {
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "100vh" }}
    >
      <Watch
        height="60"
        width="60"
        radius="48"
        color="#34C759"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </Stack>
  );
}

export default loader;
