import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function Toast(props) {

  const {show, msg, type, setToast} = props

  useEffect(() => {
    // console.log(props);
    setToastState({
      open: show,
      vertical: "bottom",
      horizontal: "right",
      text: msg,
      duration: props.time ? props.time : 2000,
      severity: type,
    })
  }, [props])  
  
  const [toastState, setToastState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "right",
    text: '',
    duration: 1000,
    severity: type,
  });

  const { vertical, horizontal, open, text, duration, severity } = toastState;

  const showToast = (newState) => {
    setToastState({
      open: true,
      vertical: "top",
      horizontal: "right",
      ...newState,
    });
  };

  const handleToastClose = () => {
    setToast({
      show: false,
      msg,
      type: type
    })
    setToastState({ ...toastState, open: false });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleToastClose}
      message={text}
      key={vertical + horizontal}
      autoHideDuration={duration}
    >
      <Alert
        onClose={handleToastClose}
        severity={severity}
        sx={{ width: "100%" }}
        variant='filled'
      >
        {text}
      </Alert>
    </Snackbar>
  );
}
