import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.

const bsWhite = "#fff";
const bsDark = "#1A1717";
const bsGreenBtn = "#ECFCEE";
const hoverBtn = "#B4F3BC";
const bsDarkGreen = "#34C759";
const bsTextH5 = "#1F2633";
const primaryBtn = "#623CE7";
const textCaption = "#797979";
const textBody1 = "#797979";
const textBody2 = "#fff";
const textParagraph = "#1F2633";
const textH5 = "#000";
const chip = "rgba(236, 234, 254, 0.3)";
const table = "#F3F5FA";
const filters = "#98A9BC";
const grey = "#E4E4E4";

const theme = createTheme({
  components: {
    // Name of the component ⚛️
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: `${chip}`,
          color: "#797979",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 400,
          // background: `${bsDarkGreen}`
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          // border: `1px solid #797979`,
          borderRadius: "50%",
          // backgroundColor: "#000",
          color: `${bsWhite}`,
          backgroundColor: `${bsDarkGreen}`,
          // padding: 8,
        },
        "& .Mui-active": {
          color: `${bsDarkGreen}`,
          // backgroundColor: `${bsDarkGreen}`,
        },
        "& .Mui-completed": {
          color: `${bsDarkGreen}`,
          // backgroundColor: `${bsDarkGreen}`,
        },
      },
    },
  },
  palette: {
    common: {
      white: `${bsWhite}`,
      dark: `${bsDark}`,
      lightgreen: `${bsGreenBtn}`,
      green: `${bsDarkGreen}`,
      hoverBtn: `${hoverBtn}`,
      primaryBtn: `${primaryBtn}`,
      textH5: `${bsTextH5}`,
      textBody2: `${textBody2}`,
      textH5: `${textH5}`,
      textBody1: `${textBody1}`,
      table: `${table}`,
      paragraph: `${textParagraph}`,
      filters: `${filters}`,
      grey: `${grey}`,
    },
    primary: {
      main: `${bsWhite}`,
    },
    secondary: {
      main: `${bsDark}`,
    },
    error: {
      main: red.A400,
    },
    success: {
      main: `${bsGreenBtn}`,
    },
    grey: {
      main: `${grey}`,
    },
    green: {
      main: `${bsDarkGreen}`,
    },
    primaryBtn: {
      main: `${bsDarkGreen}`,
    },
    background: {
      // default: `${bsWhite}`,
      // paper: "none",
    },
    // text: {
    //   primary: "#f1f5f9",
    //   secondary: "#94a3b8",
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    // fontFamily: ["Poppins", "sans-serif"].join(","),
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    h3: {
      fontWeight: 400,
      fontSize: "40px",
      color: `${bsTextH5}`,
    },
    h4: {
      fontWeight: 600,
      fontSize: "30px",
      color: `${bsTextH5}`,
    },
    h5: {
      fontWeight: 700,
      fontSize: "30px",
      color: `${bsTextH5}`,
    },
    h6: {
      fontWeight: 400,
      fontSize: "19px",
      color: `${textH5}`,
    },
    body1: {
      fontSize: "14px",
      fontWeight: 300,
    },
    caption: {
      fontSize: "12px",
      color: `${textCaption}`,
      fontWeight: 300,
    },
    // body2: {
    //   fontSize: "14px",
    //   color: `${textBody2}`,
    //   fontWeight: 300,
    // },
    // caption: {
    //   fontSize: "10px",
    //   color: `${textCaption}`,
    //   fontWeight: 300,
    // },
    p: {
      fontSize: "14px",
      color: `${textParagraph}`,
      fontWeight: 400,
    },
  },
});

export default theme;
