// components/Layout.js
import React, { useState, useEffect, useContext, Fragment } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Image from "next/image";
import AuthContext from "../context/AuthContext";
import PrimarySideNavBar from "../components/sidenavs/primary";
import axios from "axios";
import LoginPage from "../pages/Login";
import Person2Outlined from "@mui/icons-material/PersonOutlined";
import { useRouter } from "next/router";
import PageLoader from "../components/loader";
import cookie from "js-cookie";
import { usePathname } from "next/navigation";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import QrCodeIcon from "@mui/icons-material/QrCode";

const Layout = ({ children }) => {
  const theme = useTheme();
  const router = useRouter();
  const pathname = usePathname();

  const drawerWidth = 240;
  const { user, login, logout } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    backgroundColor: "#1A1717",
    //   borderTopRightRadius: "15px !important"
  });
  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    backgroundColor: "#1A1717",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));
  // Appbar
  const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  });
  const Icons = styled("div")(() => ({
    display: "flex",
    gap: "20px",
    alignItems: "center",
  }));

  const [menuItems, setMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageAccess, setPageAccess] = useState(true);

  useEffect(() => {
    login();
  }, []);

  useEffect(() => {
    fetchSideNav(user);
    validateRoute(user);
  }, [user]);

  const fetchSideNav = async (user) => {
    if (user) {
      const token =
        user && user.roleId == 1 ? cookie.get("stoken") : cookie.get("token");
      await axios
        .get(`/api/global/system/getSideNav?roleId=${user ? user.roleId : 4}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((response) => {
          let x = response.data.result
            ? JSON.parse(response.data.result[0].nav_menu_template)
            : [];
          setMenuItems(x.menu);
        });
    }
  };

  const validateRoute = async (user) => {
    if (user) {
      let token = cookie.get("token");
      if (user.roleId == 1) {
        token = cookie.get("stoken");
      }
      setIsLoading(true);
      await axios
        .get(
          `/api/global/system/validateRoute?pathname=${
            router.pathname
          }&roleId=${user ? user.roleId : 7}`,
          {
            headers: {
              "x-auth-token": token,
            },
          }
        )
        .then((response) => {
          setPageAccess(response.data.status);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const layoutContainer = {
    my: 5,
    "& h3": {
      fontSize: 30,
      fontWeight: 700,
      mb: 1,
    },
    "& h5": {
      fontSize: 14,
      fontWeight: `100 !important`,
    },
  };

  let variableWidth = "100%";
  if (
    router.pathname.includes("/eventTables/") ||
    router.pathname.includes("/seatmaps")
  ) {
    variableWidth = "81%";
  }

  const handleNavigateTheme = (event) => {
    event.preventDefault();
    router.push("/theme");
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          {router.route == "/_error" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <Typography variant="h4">404</Typography>
            </Box>
          ) : (
            <Fragment>
              {user ? (
                <>
                  {router.pathname == "/Login2" ? (
                    <Box component="main">
                      <LoginPage />
                    </Box>
                  ) : (
                    <Box
                      component="main"
                      sx={{
                        ...layoutContainer,
                        display: "flex",
                        width: variableWidth,
                      }}
                    >
                      <Box>
                        <AppBar
                          position="fixed"
                          open={open}
                          color="primary"
                          elevation={0}
                          sx={{
                            filter: `drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.06))`,
                          }}
                        >
                          <StyledToolbar
                            disableGutters
                            sx={{
                              padding: "0px 40px 0px 30px !important",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IconButton
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                  ...(open && { display: "none" }),
                                }}
                              >
                                <MenuIcon />
                              </IconButton>
                              <Box
                                size="small"
                                sx={{
                                  ml: 2,
                                  display: {
                                    lg: "block",
                                    md: "block",
                                    sm: "block",
                                    xs: "none",
                                  },
                                  borderRadius: "50%",
                                }}
                                aria-controls={
                                  open ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                              >
                                {user.roleId == 1 ? (
                                  <Person2Outlined />
                                ) : (
                                  <Image
                                    className="profile_img object_fit_cover"
                                    src={user.imgPath}
                                    loader={() => user.imgPath}
                                    alt="logo"
                                    width={35}
                                    height={35}
                                  />
                                )}
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  ml: 1,
                                  textTransform: "capitalize",
                                  display: {
                                    lg: "block",
                                    md: "block",
                                    sm: "block",
                                    xs: "none",
                                  },
                                }}
                              >
                                {user.title}
                              </Typography>
                            </Box>
                            <Icons>
                              <Box
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => router.push('/whatsappQrCodes')}
                              >
                                {user.roleId != 1 && (
                                  <>
                                    <IconButton
                                      variant="contained"
                                      elevation={1}
                                      sx={{
                                        ml: 0.5,
                                        backgroundColor:
                                          theme.palette.common.white,
                                        filter: `drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06))`,
                                        borderRadius: 2,
                                        "&: hover": {
                                          backgroundColor:
                                            theme.palette.common.white,
                                          color: theme.palette.common.green,
                                        },
                                      }}
                                    >
                                      <QrCodeIcon />
                                    </IconButton>
                                    <Typography
                                      variant="caption"
                                      // sx={{ ml: 1 }}
                                    >
                                      WhatsApp QR Codes
                                    </Typography>
                                  </>
                                )}
                              </Box>
                              <Box
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => handleNavigateTheme(e)}
                              >
                                {user.roleId != 1 && (
                                  <>
                                    <IconButton
                                      variant="contained"
                                      elevation={1}
                                      sx={{
                                        ml: 0.5,
                                        backgroundColor:
                                          theme.palette.common.white,
                                        filter: `drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06))`,
                                        borderRadius: 2,
                                        "&: hover": {
                                          backgroundColor:
                                            theme.palette.common.white,
                                          color: theme.palette.common.green,
                                        },
                                      }}
                                    >
                                      <SettingsSuggestIcon />
                                    </IconButton>
                                    <Typography
                                      variant="caption"
                                      // sx={{ ml: 1 }}
                                    >
                                      Settings
                                    </Typography>
                                  </>
                                )}
                              </Box>
                              <Box sx={{ cursor: "pointer" }} onClick={logout}>
                                <IconButton
                                  variant="contained"
                                  elevation={1}
                                  sx={{
                                    ml: 0.5,
                                    backgroundColor: theme.palette.common.white,
                                    filter: `drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06))`,
                                    borderRadius: 2,
                                    "&: hover": {
                                      backgroundColor:
                                        theme.palette.common.white,
                                      color: theme.palette.common.green,
                                    },
                                  }}
                                >
                                  <LogoutIcon />
                                </IconButton>
                                <Typography variant="caption" sx={{ ml: 1 }}>
                                  Logout
                                </Typography>
                              </Box>
                            </Icons>
                          </StyledToolbar>
                        </AppBar>
                        {/* ======================================== Appbar End================================================*/}
                      </Box>
                      <CssBaseline />
                      <Drawer variant="permanent" open={open}>
                        <DrawerHeader>
                          <IconButton
                            onClick={handleDrawerClose}
                            color="primary"
                          >
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                          </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <PrimarySideNavBar
                          menuItems={menuItems}
                          isLoading={isLoading}
                        />
                        {/* <DrawerFooter>
                      <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} color="primary">
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                      </IconButton>
                    </DrawerFooter> */}
                      </Drawer>
                      {pageAccess ? (
                        <Box
                          component="main"
                          sx={{ p: 3, width: "100%", mt: 5 }}
                        >
                          {children}
                        </Box>
                      ) : (
                        <Box
                          component="main"
                          sx={{
                            p: 3,
                            height: "90vh",
                            width: "100%",
                            mt: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h3">
                            Permission Required
                          </Typography>
                          <Typography variant="h5">
                            You don't have access to view this page
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </>
              ) : (
                <Box component="main">
                  <LoginPage />
                </Box>
              )}
            </Fragment>
          )}
        </>
      )}
    </>
  );
};

export default Layout;
