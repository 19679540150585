import React, { Fragment } from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AdjustIcon from "@mui/icons-material/Adjust";
import { useRouter } from "next/router";
import { useTheme } from "@mui/material/styles";
import { Stack } from '@mui/system';
import { nanoid } from 'nanoid';

function Primary({menuItems, isLoading}) {
    const theme = useTheme();
    const router = useRouter();
    
    return (
        <Fragment>
            {isLoading ? (
                <Box sx={{p:2}}>
                    {Array(13).fill().map(()=>(
                        <Stack display="flex" flexDirection="row" justifyContent="space-between" key={nanoid(20)} sx={{mb:2, alignItems:'center'}} >
                            <Skeleton variant="circular" sx={{width:'35px', height:'30px', background:`#5D5D5D`}} />
                            <Skeleton variant="p" sx={{width:'100%',background:`#5D5D5D`, ml:2}} />
                        </Stack>
                    ))}
                </Box>
            ):(
                <>
                    <List disablePadding>
                        {menuItems && menuItems.map((item) => (
                            <>
                            {item.label && (
                                <ListItem
                                    key={item.label}
                                    disablePadding
                                    sx={{ display: "block" }}
                                >
                                    <ListItemButton
                                        onClick={() => router.push(item.link)}
                                        sx={{
                                            minHeight: 60,
                                            justifyContent: open ? "initial" : "center",
                                            px: 2.5,
                                        }}
                                    >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : "auto",
                                            justifyContent: "center",
                                            color: theme.palette.primary.main,
                                            borderBottom: `1px solid ${theme.palette.primary.borderColor}`
                                        }}
                                    >
                                        <AdjustIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.label}
                                        sx={{
                                        opacity: open ? 1 : 0,
                                        color: theme.palette.primary.main,
                                        fontSize: theme.typography.body2,
                                        }}
                                    />
                                    </ListItemButton>
                                </ListItem>
                            )}
                            </>
                        ))}
                    </List>
                </>
            )}
        </Fragment>
    )
}

export default Primary;