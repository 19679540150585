import React, { useEffect, useState, useContext } from "react";
import {
  Stack,
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Image from "next/image";
import logo from "../public/logos/main-logo-black.png";
import ButtonSpinner from "../components/spinners/ButtonSpinner";
import cookie from "js-cookie";
import axios from "axios";
import { encrypt, decrypt } from "../config/encryption";
import Toast from "../components/Toast";
import LoginImage from "../public/images/loginImage.png";
import Link from "next/link";
import { useRouter } from "next/router";
import Loader from "../components/loader";
import Head from "next/head";
import AuthContext from "../context/AuthContext";

const inputTextfield = {
  mt: 1,
  mb: 2,
  borderRadius: 3,
  "& .MuiOutlinedInput-input": {
    background: "#efefef",
    outline: "1px !important",
  },
  "& .MuiOutlinedInput-input:focus": {
    outline: "1px !important",
  },
  "& .MuiOutlinedInput-input:active": {
    outline: "1px !important",
  },
  "& .MuiOutlinedInput-input:inactive": {
    outline: "1px !important",
  },
};

const outerBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  margin: "0 auto",
};

const layoutButton = {
  borderRadius: "10px",
  fontSize: `16px`,
  fontWeight: `700 !important`,
  border: `1px solid #61FC6E`,
  background: "#61FC6E",
  color: "#000000",
  opacity: 1,
  minWidth: `100px`,
  padding: `14px 20px`,
  display: "block",
  textAlign: "center !important",
  boxShadow: "none",
  "&:hover": {
    background: "#61FC6E",
    color: "#000000",
    border: `1px solid #61FC6E`,
    boxShadow: "none",
  },
};

export default function Login2() {
  const { user, updateUser, login, logout } = useContext(AuthContext);
  const router = useRouter();
  const { un, pwd } = router.query;
  const [pageLoad, setPageLoad] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setEmail(un ? un : "");
      setPassword(pwd ? pwd : "");
      submitFormData();
      setPageLoad(false);
    }, 2000);
  }, [router.query]);

  const [error, seterror] = useState({
    email: false,
    password: false,
  });
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: "",
    type: "success",
  });

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const submit = async () => {
    submitFormData();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  const submitFormData = async () => {
    if (email == "" || password == "") {
      seterror({
        email: email == "",
        password: password == "",
      });
    } else {
      seterror({
        email: false,
        password: false,
      });

      setLoading(true);

      const data = encrypt(
        JSON.stringify({
          email,
          password,
        })
      );

      await axios
        .post("/api/auth/login", {
          data,
        })
        .then((resp) => {
          const data = JSON.parse(decrypt(resp.data.data));
          updateUser(data);
          if (data.roleId == 1) {
            cookie.set("stoken", data.token);
          } else {
            cookie.set("token", data.token);
          }
          cookie.set("clubId", data.clubId);
          cookie.set("roleId", data.roleId);

          if (data.roleId == 1) {
            window.location.href = "/onelink/profiles";
          } else if (data.roleId == 9 || data.roleId == 10) {
            window.location.href = "/googleReviews";
          } else if (data.roleId == 11) {
            window.location.href = "/kioskManagement";
          } else {
            window.location.href = "/bookings";
          }

          // window.location.href =
          //   data.roleId == 1
          //     ? "/"
          //     : data.roleId == 9 || data.roleId == 10
          //     ? "/googleReviews"
          //     : "/bookings";
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);

          if (err.response) {
            setToast({
              show: true,
              msg: err.response.data,
              type: "error",
            });
          }
        });
    }
  };

  return (
    <>
      {pageLoad ? (
        <Loader />
      ) : (
        <>
          <Head>
            <title>Clubr Dashboard | Login</title>
          </Head>
          <Box sx={{ ...outerBox, p: 2 }} maxWidth="md">
            <Grid container spacing={7}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{ alignSelf: "center", justifyContent: "center" }}
              >
                <Image
                  src={LoginImage}
                  alt="..."
                  style={{ width: "100%", height: "100%" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{ alignSelf: "center" }}
              >
                <Image
                  src={logo}
                  width={125}
                  height={35}
                  alt="clubr-backstage-logo"
                />
                <Box component="form" autoComplete="off" sx={{ mt: 1 }}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: `900 !important`,
                      fontSize: 24,
                      my: 3,
                      mb: 5,
                    }}
                  >
                    Login to Clubr Backstage
                  </Typography>
                  <Typography variant="p" sx={{ fontWeight: `900 !important` }}>
                    Email*
                  </Typography>
                  <TextField
                    id="fullWidth"
                    variant="outlined"
                    type="email"
                    sx={inputTextfield}
                    fullWidth
                    required
                    error={error.email}
                    onChange={changeEmail}
                    onKeyPress={handleKeyPress}
                    value={email}
                  />
                  <Stack
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="baseline"
                  >
                    <Typography
                      variant="p"
                      sx={{ fontWeight: `900 !important`, mt: 1 }}
                    >
                      Password*
                    </Typography>
                  </Stack>
                  <TextField
                    id="fullWidth"
                    variant="outlined"
                    type="password"
                    sx={inputTextfield}
                    fullWidth
                    required
                    error={error.password}
                    onChange={changePassword}
                    onKeyPress={handleKeyPress}
                    value={password}
                  />
                  <Stack
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ ...layoutButton }}
                      onClick={submit}
                      disabled={loading || !password || !email}
                    >
                      {loading ? <ButtonSpinner /> : "Login"}
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Toast
            show={toast.show}
            msg={toast.msg}
            type={toast.type}
            setToast={setToast}
          />
        </>
      )}
    </>
  );
}
